import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby-plugin-spinner"
import { graphql, useStaticQuery } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"

import SEO from "./../components/seo"

import Layout from "../components/layout"
import slugify from "slugify"

import { PrevisuPasseSeul } from "@bko/previsus"
import { orderPp } from "../services/prixBaguettes"

const PassePartoutPage = (props) => {
  const c = (string) => string.charAt(0).toUpperCase() + string.slice(1).toLocaleLowerCase()

  const urlParams = new URLSearchParams(typeof window === "undefined" ? "" : window.location.search)
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  )
  
  return (
    <>
      <Layout banner={false} location={props.location}>
        <SEO
          title="Passe-partout"
          description="Passe partout découpe sur mesures pour encadrer vos sujets. Blanc, noir ou couleur. Epaisseur 1.4mm ou 3.3mm"
          canonical={`${site.siteMetadata.siteUrl}/passe-partout/`}
          jsonLd={[
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Accueil",
                  item: site.siteMetadata.siteUrl,
                },
                {
                  "@type": "ListItem",
                  "position": 2,
                  "name": "Passe partout",
                  "item": `${site.siteMetadata.siteUrl}/passe-partout/`
                }
              ]
            }
          ]}
        />
        <nav className="breadcrumb mb-2 mt-4">
          <ul>
            <li>
              <Link to="/">Accueil</Link>
            </li>
            <li>
              <span className="pl-3">Passe partout</span>
            </li>
          </ul>
        </nav>

        <div className="columns passesPartout">
          <div className="column">
            <h1>Passe partout</h1>
            {Object.keys(props.pageContext.passes)
              .sort((a, b) => orderPp[a] - orderPp[b])
              .map((type) => (
                <div key={slugify(type)} id={`menu-${slugify(type.replace(":", "-"))}`}>
                  <div className="h2 mb-4">{type.replace(new RegExp(/(80x120cm|80x102cm)/gi), "")}</div>
                  <div className="columns is-centered is-multiline mb-6">
                    {props.pageContext.passes[type]
                      .sort((a, b) => a.copp_order - b.copp_order)
                      .map((node, index) => (
                        <div key={index} className="column is-narrow">
                          <Link
                            to={`/passe-partout/${slugify(node.pa_libelle.replace("Passe-partout", ""))}-${node.pa_id}/${
                              urlParams.has("type") ? "?type=" + urlParams.get("type") : ""
                            }`}
                          >
                            <div title={node.pa_passe_couleur} className="pp is-relative">
                              {node.gm !== null && (
                                <div className="bandeau">
                                  XL disponible
                                  <br />
                                  en 100x150cm
                                </div>
                              )}
                              {node.gm === null ? (
                                <div className="ppDim">
                                  max: {node.pa_plaque_longueur}x{node.pa_plaque_largeur}cm
                                </div>
                              ) : (
                                <div className="ppDim">&nbsp;</div>
                              )}

                              <div className="previsu">
                                <PrevisuPasseSeul product={node} couleurInterieur={"#9F8F7E"} echantillon={true} size={150} />
                              </div>

                              <div className="pptitle">
                                Passe partout <br/>{c(
                                  node.pa_libelle
                                    .replace(
                                      new RegExp(
                                        /(Passe-partout|âme de couleur pigmentée|épaisseur [0-5],[0-9]mm|,|\(|\)|dans la masse|âme blanche|âme teintée|âme noire)/gi
                                      ),
                                      ""
                                    )
                                    .trim()
                                )}
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
          </div>
          <aside className="column is-narrow is-fullheight pt-5">
            <div className="menu" style={{width: 400}}>
              <div className="h2 is-size-4 ml-3 mt-2">Choix de la catégorie</div>
              <nav>
                <ul className="menuCategory">
                  {Object.keys(props.pageContext.passes)
                    .sort((a, b) => orderPp[a] - orderPp[b])
                    .map((type) => (
                      <a key={slugify(type)} href={`#menu-${slugify(type.replace(":", "-"))}`}>
                        <li>
                          <div className={`navbar-item disableOutline`} style={{ cursor: "pointer" }}>
                            {c(
                              type
                                .replace(new RegExp(/(80x120cm|80x102cm|Passe partout :|conservation )/gi), "")
                                .replace("Double", "Double passe partout")
                                .trim()
                            )}
                          </div>
                        </li>
                      </a>
                    ))}
                </ul>
              </nav>
              <div className="box mt-5 has-text-justified">
                <div className="h2 is-size-5 mt-0"><FontAwesomeIcon icon={faInfoCircle} /> Le passe partout</div>
                <p>Le passe-partout évite que le sujet donne le sentiment d’être enfermé par le cadre. Il est particulièrement esthétique pour votre <Link to="/tirage-photo-encadre/">photo encadrée</Link>, cadre aquarelle.</p>
                <p>Sa deuxième fonction est d’éviter que l’œuvre d’art touche le verre, ce qui peut altérer le sujet et créer, avec la tention superficielle, un effet comme si le sujet était mouillé (tâches plus foncées notamment sur les photos brillantes).</p>
                <p>Les fenêtres images sont découpées numériquement avec chanfrein à 45° pour un rendu optimal.</p>
              </div>
            </div>
          </aside>
        </div>
        <div className="container mt-6">
        <p>Une fois l’instant capté par l’œil du photographe ou fixé par les touches expressives du
peintre, il ne reste plus qu’à l’encadrer afin de le magnifier et lui offrir l&#39;éternité.
L’encadrement sur mesure a pour objectif, en effet, de sublimer l’œuvre et non de
l’amoindrir, mais pour cela, il faut choisir son cadre avec attention et y ajouter un passe-
partout sur mesure, cadre en carton qui tient un double rôle : esthétique et protecteur.</p>
<p>La boutique en ligne, Encadrement sur mesure, vous présente une très belle gamme de
cadres, mais aussi de passe-partout sur mesure, éléments indispensables afin d’obtenir un
rendu d’exception. Découvrez des passe-partout sur mesure que nous déclinons dans
plusieurs épaisseurs -de 1,4 mm, 1,5 mm, 1,7 mm, 2,5 mm, 3,3 mm- et divers coloris -
crème, blanc cassé, extra blanc, âme couleur pigmentée, âme teintée dans la masse qualité
conservation, âme teintée, âme blanche, âme noire- qui s’adapteront parfaitement à votre
travail et le mettra parfaitement en valeur.</p>
<h2>Le passe-partout sur mesure : la solution idéale pour libérer le regard</h2>
<p>Le <strong>passe-partout</strong> est un accessoire d’encadrement indispensable à la mise en
valeur d’une œuvre. Il offre, en effet, une aération visuelle dont l’œil a besoin pour profiter
pleinement du spectacle qui lui ait donné d’admirer. Pour cela, il faut choisir le format
adéquat qui valorisera l&#39;œuvre.</p>
<p>Grâce au passe-partout, par ailleurs, le sujet présenté respire et se suffit à lui-même. Le
cadre choisi permettra d’accentuer cet effet et de mettre davantage en avant l’œuvre.</p>
<p>Non seulement le passe-partout sur mesure offre une excellente qualité visuelle, mais en
plus, il permet de centrer votre travail de sorte qu’il présente parfaitement bien. Dans le
cadre d’une exposition, le choix d&#39;un passe-partout fera, d&#39;ailleurs, toute la différence.</p>
<p>Si vous apercevez, enfin, quelques imperfections en dehors de l’objet central de votre
œuvre, le passe-partout permettra de les cacher de sorte qu&#39;elles ne retiendront pas
l&#39;attention.</p>
<h2>Le passe-partout constitue une protection adéquate entre l’œuvre et le verre
du cadre</h2>
<p>Le passe-partout sur mesure n’est pas seulement un accessoire esthétique, mais aussi
pratique grâce auquel vous protégerez votre œuvre du contact avec le verre. Contrairement
à la marie-louise, en effet, le <strong>passe-partout</strong> s’utilise uniquement dans des encadrements
avec verre et tient le rôle de couche protectrice alors que la marie-louise ne joue qu’un rôle
esthétique.</p>
<p>Le contact durable entre l’œuvre et le verre peut altérer à terme le support. Concernant les
papiers photographiques, ceux-ci peuvent se coller au verre dans le temps. Des taches
notamment apparaîtront sur les papiers photographiques. Protégez donc votre œuvre grâce
au passe-partout, pour laisser respirer le papier.</p>
<p>Merci de noter que nous proposons un carton barrière en option qui évite que le sujet soit en
contact direct avec le fond du cadre (isorel).</p>
<p>Le travail de nos équipes vous offre une précision extraordinaire pour la création de vos
passe-partout sur mesure. Toutes les découpes, en effet, sont numériques, pour un rendu
final exceptionnel. Afin de vous rendre compte de la qualité de nos passe-partout, n’hésitez
pas à nous demander un échantillon de 10 x 10 cm -fenêtre image : 5 x 5 cm-, à un prix
modique avec des frais de port et emballages offerts.</p>
<p>Vous recevrez vos passe-partout sur mesure dans un délai de huit jours ouvrés ou bien
choisissez l’option « EXPRESS » afin d’obtenir votre commande au terme d’un jour ouvré.</p>
        </div>
      </Layout>
    </>
  )
}

PassePartoutPage.propTypes = {
  mesPassePartout: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

export default PassePartoutPage
